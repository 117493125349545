// REACT
import React, { useState, useEffect } from 'react';
// IMAGES
// logo and background elements
import logo from './img/logo.svg';
import logoWhite from './img/logo-white.svg';
// aigida
import un from './img/aigida/un.webp';
import elDim from './img/aigida/min_digi.webp';
import dimath from './img/aigida/dim_ath_2.webp';
// sponsors
import ICANN from './img/sponsors/icann.webp';
import ISF from './img/sponsors/isf.webp';
import code4thought from './img/sponsors/c4t.webp';
import kg from './img/sponsors/kg.webp';
import hl from './img/sponsors/holy_lama.webp';
import fhats from './img/sponsors/4hats.webp';
// supporters
import wefor from './img/supporters/wefor.webp';
import w4c from './img/supporters/w4c.webp';
import rythmisis from './img/supporters/rythmisis.webp';
import infinityGR from './img/supporters/infinityGR.webp';
import bombinate from './img/supporters/bombinate_w.webp';
import ai_expl from './img/supporters/ai_expl.webp';
import athenslegal from './img/supporters/athenslegal.webp';
import shetells from './img/supporters/shetells.webp';
import womenontop from './img/supporters/womenontop.webp';
import welead from './img/supporters/welead.webp';
// com_supporters
import naftemporiki from './img/com_supporters/naftemporiki2.webp';
import apempe from './img/com_supporters/apempe.webp';
import startup from './img/com_supporters/Startup_Logo.webp';
import athina from './img/com_supporters/984.webp';
import athvoice from './img/com_supporters/athvoice.webp';
import epixeiro from './img/com_supporters/epixeiro.webp';
import flustgr from './img/com_supporters/flustgr.webp';
import insider from './img/com_supporters/insider.webp';
import olafaq from './img/com_supporters/olafaq.webp';
//speakers info
import { speakers } from './speakers';
import speakercard1 from './img/speakercard1.webp';
import speakercard2 from './img/speakercard2.webp';

// social
import facebook from './img/social/facebook.svg';
import instagram from './img/social/instagram.svg';
import linkedin from './img/social/linkedin.svg';
import tiktok from './img/social/tiktok.svg';
import youtube from './img/social/youtube.svg';
// css
import './App.scss';
// canvas
import { Canvas } from "@react-three/fiber";
// components
import Blob from "./Blob";

// cookies
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
  const [viewAll, setViewAll] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 480);
  };

  const handleViewAll = () => {
    setViewAll(true);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  
    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    useEffect(() => {
      CookieConsent.run({
        categories: {
          necessary: {
              enabled: true,  // this category is enabled by default
              readOnly: true  // this category cannot be disabled
          },
          analytics: {}
      },
  
      language: {
          default: 'en',
          translations: {
              en: {
                  consentModal: {
                      title: 'We value your privacy',
                      description: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. <a href="/privacy_policy.pdf">Privacy Policy</a>',
                      acceptAllBtn: 'Accept all',
                      acceptNecessaryBtn: 'Reject all',
                      showPreferencesBtn: 'Manage Individual preferences'
                  },
                  preferencesModal: {
                      title: 'Manage cookie preferences',
                      acceptAllBtn: 'Accept all',
                      acceptNecessaryBtn: 'Reject all',
                      savePreferencesBtn: 'Accept current selection',
                      closeIconLabel: 'Close modal',
                      sections: [
                          {
                              title: '',
                              description: 'We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below. <br/>The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.<br/> We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.<br/>You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.'
                          },
                          {
                              title: 'Strictly Necessary cookies',
                              description: 'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences.',
  
                              //this field will generate a toggle linked to the 'necessary' category
                              linkedCategory: 'necessary'
                          },
                          {
                              title: 'Performance and Analytics',
                              description: 'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
                              linkedCategory: 'analytics'
                          },
                          {
                              title: 'More information',
                              description: 'For any queries in relation to my policy on cookies and your choices, please contact us</a>'
                          }
                      ]
                  }
              }
          }
      }
      });
  }, []);
  
  return (
    <div className="body">
      <header className="App-header">
        <img src={logo} className="logo-dws" alt="logo of Digital World Summit Greece"/>
        <div className="main-info">
          <br/>
          <h1>Shaping<br/>the future of AI</h1>
          <br/><br/>
          <h4>Τρίτη 28 Μαΐου 2024</h4>
          <p>Εθνικό Ίδρυμα Ερευνών, Αθήνα</p>
          <br/>
          <div className="live">
          <a href="https://www.youtube.com/playlist?list=PLLz6dTxQ6FOK62qD_lg0FOVg2bRzoCBcX" target="_blank" rel="noopener noreferrer">
          <button className="live">Watch Recording</button>
          </a>
          </div>
        </div>
        <div className="animation">
        <Canvas camera={{ position: [0.0, 0.0, 8.0], height:1000}}>
          <Blob />
        </Canvas>
        </div>
      </header>
      <div className="what-is-it section">
        <h2 id="what-1">Από την <br/> Ελλάδα στα <br/> Ηνωμένα Έθνη</h2>
        <p id="what-2">Το Digital World Summit Greece 2024 συνδέει την Ελλάδα με τις παγκόσμιες τεχνολογικές εξελίξεις 
          της τεχνητής νοημοσύνης. <br/><br/> Φιλοξενεί διακεκριμένες προσωπικότητες με στόχο την χάραξη προτάσεων πολιτικής 
          που ανταποκρίνονται στην ελληνική πραγματικότητα.</p>
      </div>
      <div className="ypo-tin-aigida section">
        <div className="blob-1-background"></div>
        <div className="title">
          <h3>Υπο την αιγίδα</h3>
        </div>
        <div className="top">
          <a href="https://www.un.org/" target="_blank">
          <img src={un} className="logo-un" alt="λογότυπο του Οργανισμού Ηνωμένων Εθνών /logo of United Nations"/></a>
          <a href="https://mindigital.gr/" target="_blank">
          <img src={elDim} className="logo" alt="λογότυπο του Υπουργείου Ψηφιακής Διακυβέρνησης / logo of Ministry of Digital Governance Greece"/></a>
          <a href="https://www.cityofathens.gr/" target="_blank">
          <img src={dimath} className="logo" alt="λογότυπο του Δήμου Αθηναίων / logo of Ministry of Digital Governance Greece"/></a>
        </div>
      </div>
      <div className="speakers section">
        <div className="speakers-title title">
          <h3>Introducing the Speakers</h3>
        </div>
        <div className="cards">
        {(isMobile && !viewAll) && 
         <>
          {speakers.slice(0, 4).map( (speaker) =>
         
            <div className="speaker-card" key={speaker.name}>
              <img src={speaker.photo} className="speaker-img" alt={speaker.name}/>
              <div className="info">
                <h6>{speaker.name}</h6>
                <p>{speaker.info}</p>
              </div>
            </div>
            
          
          )}
          <button className="register" onClick={handleViewAll}>View all speakers</button>
          </>
          }
          {(!isMobile || (isMobile && viewAll)) && speakers.map( (speaker) =>
            <div className="speaker-card" key={speaker.name}>
              <img src={speaker.photo} className="speaker-img" alt={speaker.name}/>
              <div className="info">
                <h6>{speaker.name}</h6>
                <p>{speaker.info}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="agenda section">
        <div className="title">
          <h3>Agenda</h3>
          <div className="agenda-grid-1">
            <div className="opening">
              <img src={speakercard1} className="agenda-img" alt="decorative image with flags and the building of United Nations"/>
              <p className="agenda-time">10:00 – 10:45</p>
              <h4 className="agenda-title">Opening Ceremony</h4>
              <p className="agenda-speakers">Κωνσταντίνα Ναθαναήλ<br/>Κωνσταντίνος Μασσέλος</p>
            </div>
            <div className="keynote">
            <img src={speakercard2} className="agenda-img" alt="decorative image from a conference"/>
              <p className="agenda-time">10:45 – 11:15</p>
              <h4 className="agenda-title">Lightning talk</h4>
              <p className="agenda-speakers">Δημήτρης Παπαστεργίου</p>
            </div>
          </div>
          <div className="agenda-grid-2">
            <div className="topic-1">
              <p className="agenda-time">12:00 – 13:00</p>
              <h4 className="agenda-title">ΑΙ: Η ισορροπία ανάμεσα σε καινοτομία & ρύθμιση</h4>
              <p className="agenda-speakers">Δημήτρης Γερογιάννης<br/>Λίλιαν Μήτρου<br/>Χαράλαμπος Τσέκερης<br/>Γιάννης Μαστρογεωργίου<br/>Φώτης Δραγανίδης<br/>Στέφανος Βιτωράτος (Συντονιστής)</p>
            </div>
            <div className="topic-2">
              <p className="agenda-time">13:15 – 14:30</p>
              <h4 className="agenda-title">Κυβερνοασφάλεια, Ασφάλεια των Πολιτών & Τεχνητή Νοημοσύνη</h4>
              <p className="agenda-speakers">Ναταλία Σούλια (Keynote)  <br/>Θωμάς Δομπρίδης<br/>Λαμπρινή Γυφτοκώστα<br/>Αντώνης Μπρούμας<br/>Στέλλα Τσιτσούλα<br/>Κωνσταντίνος Αναγνωστόπουλος (Συντονιστής)</p>
            </div>
            <div className="topic-3">
              <p className="agenda-time">15:30 – 16:30</p>
              <h4 className="agenda-title">Το ψηφιακό μέλλον της Ευρώπης</h4>
              < div className="agenda-speakers">
                <p>Μαρία-Ωραιοζήλη Κουτσουπιά,</p> 
                <h6>Υπ. Ευρωβουλευτής Ν.Δ.</h6>
                <p>Γιώργος Καραμέρος, </p>
                <h6>Τομεάρχης Ψηφ. Διακυβέρνησης Κ.Ο. ΣΥΡΙΖΑ-ΠΣ</h6>
                <p>Γιάννης Πάζιος, </p>
                <h6>Υπ. Ευρωβουλευτής ΠΑΣΟΚ</h6>
                <p>Αντώνης Μπόγρης,</p> 
                <h6>Υπ. Ευρωβουλευτής Νέα Αριστερά</h6>
                <p>Χριστίνα Ταχιάου (Συντονίστρια)</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-to-participate">
        <div className="blob-2-background"></div>
        <div className="blob-3-background"></div>
        <h4 className="question">Γιατί να συμμετάσχεις;</h4>
        <div className="reasons">
          <p><span className="highlighted">Συνδιαμόρφωσε προτάσεις πολιτικής</span><br/> που θα προωθηθούν σε φορείς όπως τα Ηνωμένα Έθνη, η Ευρωπαϊκή Κομμισιόν και το Ευρωκοινοβούλιο.</p><br/>
          <p><span className="highlighted">Γνώρισε εκπροσώπους φορέων</span><br/> άμεσα συνδεδεμένων με το χώρο της τεχνολογίας και της καινοτομίας.</p><br/>
          <p><span className="highlighted">Παρακολούθησε από κοντά</span><br/> πάνελ αναγνωρισμένων ομιλητών με διαφορετικά επαγγελματικά υπόβαθρα.</p><br/>
        </div>
      </div>
      <div className="call-to-action">
          <h3>Book now!</h3><br/>
          <a href="#" rel="noopener noreferrer">
          <button className="register">Register</button>
          </a>
        </div>
      <div className="sponsors section">
        <div className="title">
          <h3>Χορηγοί</h3>
        </div>
        <div className="center">
          <a href="https://www.isocfoundation.org/" target="_blank">
          <img src={ISF} className="logo_sponsors" alt="logo of Internet Society foundation"/></a>
          <a href="https://www.icann.org/" target="_blank">
          <img src={ICANN} className="logo_sponsors" alt="logo of ICANN"/></a>
          <a href="https://kglawfirm.gr/" target="_blank">
          <img src={kg} className="logo_sponsors" alt="logo of Kyriakides Georgakopoulos law firm"/></a>
          <a href="https://code4thought.eu/" target="_blank">
          <img src={code4thought} className="logo_sponsors" alt="logo of Code for Thought"/></a>
          <a href="https://holyllama.gr/" target="_blank">
          <img src={hl} className="logo_sponsors" alt="logo of Holy Lamma .gr"/></a>
          <a href="https://4hats.gr" target="_blank">
          <img src={fhats} className="logo_sponsors" alt="logo four hats marketing agency"/></a>
        </div>
      </div>
      <div className="with-the-support section">
        <div className="title">
          <h3>Χορηγοί επικοινωνίας</h3>
        </div>
        <div className="center">
          <img src={naftemporiki} className="logo_com_support" alt="logo naftemporiki"/>
          <img src={apempe} className="logo_com_support" alt="logo apempe"/>
          <img src={startup} className="logo_com_support" alt="logo startup"/>
          <img src={athina} className="logo_com_support" alt="logo"/>
          <img src={epixeiro} className="logo_com_support" alt="logo epixeiro"/>
          <img src={athvoice} className="logo_com_support" alt="logo athvoice gr"/>
          <img src={flustgr} className="logo_com_support" alt="logo flustgr"/>
          <img src={olafaq} className="logo_com_support" alt="logo olafaq"/>
          <img src={insider} className="logo_com_support" alt="logo insider"/>

        </div>
      </div>
      <div className="with-the-support section">
        <div className="title">
          <h3>Με την υποστήριξη</h3>
        </div>
        <div className="center">
          <img src={infinityGR} className="logo_com_support" alt="logo"/>
          <img src={w4c} className="logo_com_support" alt="logo"/>
          <img src={rythmisis} className="logo_com_support" alt="logo"/>
          <img src={wefor} className="logo_com_support" alt="logo"/>
          <img src={bombinate} className="logo_com_support" alt="logo"/>
          <img src={shetells} className="logo_com_support" alt="logo"/>
          <img src={ai_expl} className="logo_com_support" alt="logo"/>
          <img src={athenslegal} className="logo_com_support" alt="logo"/>
          <img src={womenontop} className="logo_com_support" alt="logo"/>
          <img src={welead} className="logo_com_support" alt="logo"/>

        </div>
      </div>

      <div className="footer">
            <div className="footer-logo">
              <img src={logoWhite} className="logo-dws-footer" alt="logo"/>
            </div>
            <div className="footer-social">
              <a href="http://www.facebook.com/digitalworldsummitgr" target="_blank">
              <img src={facebook}  className="social-icon" alt="facebook icon"/></a>

              <a href="http://www.instagram.com/digitalworldsummitgr" target="_blank">
              <img src={instagram} className="social-icon" alt="instagram icon"/></a>

              <a href="https://gr.linkedin.com/company/digitalworldsummitgr" target="_blank">
              <img src={linkedin} className="social-icon" alt="linkedin icon"/></a>

              <a href="https://www.tiktok.com/@digitalworldsummitgr" target="_blank">
              <img src={tiktok} className="social-icon" alt="tiktok icon"/></a>

              <a href="https://www.youtube.com/@digitalworldsummitgr" target="_blank">
              <img src={youtube} className="social-icon" alt="youtube icon"/></a>
              
            </div>
            <div className="footer-privacy">
              <a href="mailto:info@digitalworldsummit.gr">info [at] digitalworldsummit.gr</a><br/><br/>
              <a href="/privacy_policy.html">Privacy Policy</a>
            </div>
      </div>
    </div>
  );
}

export default App;
