// speakers photos
import tsitsoula from './img/speakers/tsitsoula.webp';
import papastergiou from './img/speakers/papastergiou.webp';
import masselos from './img/speakers/masselos.webp';
import tsekeris from './img/speakers/tsekeris.webp';
import mitrou from './img/speakers/mitrou.webp';
import gerogiannis from './img/speakers/gerogiannis.webp';
import gyftokosta from './img/speakers/gyftokosta.webp';
import mastrogeorgiou from './img/speakers/mastrogeorgiou.webp';
import draganidis from './img/speakers/draganidis.webp';
import mproumas from './img/speakers/mproumas.webp';
import anagnostopoulos from './img/speakers/anagnostopoulos.webp';
import soulia from './img/speakers/soulia.webp';
import vitoratos from './img/speakers/vitoratos.webp';
import dombridis from './img/speakers/dombridis.webp';
import koutsoupia from './img/speakers/koutsoupia.webp';
import karameros from './img/speakers/karameros.webp';
import mpogris from './img/speakers/mpogris.webp';
import taxiaou from './img/speakers/taxiaou.webp';
import pazios from './img/speakers/pazios.webp';


export const speakers = [
    {
        "name": "Δημήτρης Παπαστεργίου",
        "photo": papastergiou, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Υπουργός Ψηφιακής Διακυβέρνησης",
        "link": ""
    }, 
    {
        "name": "Κωνσταντίνος Μασσέλος",
        "photo": masselos, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Πρόεδρος, ΕΕΤΤ",
        "link": ""
    }, 
    {
        "name": "Χαράλαμπος Τσέκερης",
        "photo": tsekeris, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Προεδρεύων & Αντɩπρόεδρος, Εθνɩκή Επɩτροπή Βɩοηθɩκής & Τεχνοηθɩκής",
        "link": ""
    }, 
    {
        "name": "Στέλλα Τσιτσούλα",
        "photo": tsitsoula, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Communications Consultant, RED.comm | Co-Founder, Women4Cyber Greece | Founder, HCSI",
        "link": ""
    },
    {
        "name": "Λίλιαν Μήτρου",
        "photo": mitrou, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Καθηγήτρια, Τμήμα Μηχανικών Πληροφοριακών & Επικοινωνιακών Συστημάτων, Πανεπιστημίου Αιγαίου",
        "link": ""
    },
    {
        "name": "Δημήτρης Γερογιάννης",
        "photo": gerogiannis, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Πρόεδρος, AI Catalyst",
        "link": ""
    },
    {
        "name": "Λαμπρινή Γυφτοκώστα",
        "photo": gyftokosta, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Director, Artificial Intelligence & Human Rights, Homo Digitalis",
        "link": ""
    },
    {
        "name": "Γιάννης Μαστρογεωργίου",
        "photo": mastrogeorgiou, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Ειδικός Γραμματέας Μακροπρόθεσμου Σχεδιασμού, Προεδρία της Ελληνικής Κυβέρνησης",
        "link": ""
    },
    {
        "name": "Φώτης Δραγανίδης",
        "photo": draganidis, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Startups Account Director, Central Europe, Microsoft EMEA",
        "link": ""
    },
    {
        "name": "Ναταλία Σούλια",
        "photo": soulia, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Senior Associate | Privacy, Data Protection & Cybersecurity, KG Law FIrm",
        "link": ""
    },
    {
        "name": "Αντώνης Μπρούμας",
        "photo": mproumas, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Επɩκεφαλής Τομέα ∆ɩκαίου καɩ Τεχνολογίας, Πλατής-Αναστασɩάδης Δ.Ε. / EY Law",
        "link": ""
    },
    {
        "name": "Κωνσταντίνος Αναγνωστόπουλος",
        "photo": anagnostopoulos, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Co-founder & Director, Athens Legal Tech",
        "link": ""
    },
    {
        "name": "Θωμάς Δομπρίδης",
        "photo": dombridis, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Προϊστάμενος Γενικής Δ/νσης Επιχειρησιακού Σχεδιασμού, Εθνική Αρχή Κυβερνοασφάλειας",
        "link": ""
    },
    {
        "name": "Στέφανος Βιτωράτος",
        "photo": vitoratos, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Partner Digital Law Experts (DLE), Συνιδρυτής Homo Digitalis",
        "link": ""
    },
    {
        "name": "Μαρία-Ωραιοζήλη Κουτσουπιά",
        "photo": koutsoupia, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Υποψήφια Ευρωβουλευτής, Νέα Δημοκρατία",
        "link": ""
    },
    {
        "name": "Γιώργος Καραμέρος",
        "photo": karameros, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Τομεάρχης Ψηφιακής Διακυβέρνησης Κ.Ο., ΣΥ.ΡΙΖ.Α. - Προοδευτική Συμμαχία",
        "link": ""
    },
    {
        "name": "Αντώνης Μπόγρης",
        "photo": mpogris, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Υποψήφιος Ευρωβουλευτής, Νέα Αριστερά",
        "link": ""
    },
    {
        "name": "Γιάννης Πάζιος",
        "photo": pazios, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Υποψήφιος Ευρωβουλευτής, ΠΑΣΟΚ",
        "link": ""
    },
    {
        "name": "Χριστίνα Ταχιάου",
        "photo": taxiaou, //for new speakers you have to define the name and the path of the photo at the top of the file
        "info": "Head of PR, 4Hats Conceptual Agency",
        "link": ""
    },
]